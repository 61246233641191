@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	font-size: 14px;
	box-sizing: border-box;

	/* general color variables */

	--primary-color: #0066f5;
	--primary-text-black: #091f3e;
	--primary-color-hover: #0251be;
	--text-color-light: #fff;
	--accent-color: #2eb875;
	--light-grey-color: #d9d9d9;
	--text-color-secondary: #a9abb9;
	--text-color-dark: #192941;
	--text-color-dark-secondary: #51555b;
	--select-border: #777;
	--col1: #e57858;
	--col2: #e5577d;
	--angle1: 14;
	--angle2: 344;
	--angle: 160;
	--select-focus: blue;
	--error-color: red;
	--tw-ring-offset-shadow: 0 0 #0000;
	--tw-ring-shadow: 0 0 #0000;
	--tw-shadow: 0 0 #0000;
	--tw-shadow-colored: 0 0 #0000;
	--select-arrow: var(--select-border);
	--primary-font: 'Silka', sans-serif;
}

*,
*::before,
*::after {
	box-sizing: inherit;
	font-family: var(--primary-font);
}
* {
	-webkit-tap-highlight-color: transparent !important;
	font-family: var(--primary-font);
}
body {
	margin: 0;
	padding: 0;
	font-family: var(--primary-font);
	background-color: #ffffff;
}
@font-face {
	font-family: 'Silka';
	/* lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
	font-display: swap;

	src: local('Silka'),
		url('/assets/fonts/silka/silka-regular-webfont.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Silka';
	/* Lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
	font-display: swap;
	src: local('Silka'),
		url('/assets/fonts/silka/silka-medium-webfont.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Silka';
	/* lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
	font-display: swap;
	/* Local allows you to reference, load, and use locally installed fonts. If the user already has the font installed on their system, this bypasses the network entirely, and is the fastest. */
	src: local('Silka'),
		url('/assets/fonts/silka/silka-bold-webfont.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Silka';
	/* lets us decide how their web fonts will render (or fallback), depending on how long it takes for them to load. */
	font-display: swap;
	src: local('Silka'),
		url('/assets/fonts/silka/silka-light-webfont.ttf') format('truetype');
	font-weight: lighter;
	font-style: normal;
}
img {
	display: inline-block;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

a {
	text-decoration: none;
	color: inherit;
}

.h-30 {
	height: 30px;
}

select {
	/* A reset of styles, including removing the default dropdown arrow */
	cursor: pointer;
	font-size: 16px;
	font-family: inherit;
	color: #ffffff;
	padding: 0.2em 1em;
	line-height: 1.3;
	height: 40px;
	width: 100%;
	box-sizing: border-box;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
	margin: 0;
	border: 2px solid #bac1d8;
	-moz-appearance: none;
	-webkit-appearance: none;
	appearance: none;
	background-color: white;
	background-image: url('/assets/img/select.svg'), linear-gradient(white, white);
	background-repeat: no-repeat;
	background-position: right 0.7em top 50%;
	color: black;
	background-size: 1.65em auto;
}
/* Remove dropdown arrow in IE10 & IE11 */
select::-ms-expand {
	display: none;
}

select,
select::after {
	grid-area: select;
}
/* Interim solution until :focus-within has better support */
select:focus + .focus {
	position: absolute;
	top: -1px;
	left: -1px;
	right: -1px;
	bottom: -1px;
	border: 2px solid var(--select-focus);
	border-radius: inherit;
}

select[multiple] {
	padding-right: 0;

	/*
   * Safari will not reveal an option
   * unless the select height has room to 
   * show all of it
   * Firefox and Chrome allow showing 
   * a partial option
   */
	height: 6rem;

	/* 
   * Experimental - styling of selected options
   * in the multiselect
   * Not supported crossbrowser
   */
	/* //   &:not(:disabled) option {
  //     border-radius: 12px;
  //     transition: 120ms all ease-in;

  //     &:checked {
  //       background: linear-gradient(hsl(242, 61%, 76%), hsl(242, 61%, 71%));
  //       padding-left: 0.5em;
  //       color: black !important;
  //     }
  //   } */
}

select[multiple]option {
	white-space: normal;

	/* Only affects Chrome */
	outline-color: var(--select-focus);
}
.select--disabled {
	cursor: not-allowed;
	background-color: #eee;
	background-image: linear-gradient(to top, #ddd, #eee 33%);
}

label {
	font-size: 1.125rem;
	font-weight: 500;
}

.select + label {
	margin-top: 2rem;
}

.pills {
	padding: 0.6em 0.9em;
	border-radius: 5em;
	font-size: 16px;
	font-weight: 500;
	color: var(--primary-text-black);
	background-color: #e6e8eb;
	cursor: pointer;
	border: 1px solid #dadada;
	white-space: nowrap;
	overflow: hidden;
	width: auto;
}
.small-pill {
	padding: 0.2em 0.9em;
	border-radius: 5em;
	font-size: 16px;
	font-weight: 500;
	color: var(--primary-text-black);
	background-color: #e6e8eb;
	cursor: pointer;
	border: 1px solid #dadada;
	white-space: nowrap;
	overflow: hidden;
	width: auto;
}
.pills:hover,.small-pill:hover {
	background-color: #c7c9cc;
	transition: all 0.3s ease-in-out;
}
.pills.active,.small-pill.active {
	background-color: black;
	color: white;
}

.font_medium {
	font-size: 1.5rem;
	text-align: center;
	font-weight: 500;
}
.scale_transition {
	transform: scale(1.2);
	transition: transform 1s ease-out;
}
.remove-scroll {
	overflow: hidden;
}

.button_modal {
	height: 67px;
	width: 67px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: #ffffff;
	border: 2px solid #bac1d8;
	padding: 0.5rem 1.2rem;
	font-weight: 500;
	color: white;
	cursor: pointer;
	border-radius: 50%;
	font-size: 2rem;
	flex-direction: row;
	gap: 8px;
}
.button_modal img {
	width: 100%;
}
.button_modal:disabled {
	background-color: #dddddd !important;
	cursor: not-allowed;
	opacity: 1 !important;
	border: none !important;
}
.form__button {
	background: linear-gradient(to right, #0066f5 0%, #0458cd 50%, #1f539d 100%);
	border: none;
	padding: 1.5em;
	color: white;
	margin: 20px 0 30px;
	border-radius: 0.5em;
	cursor: pointer;
	width: 100%;
	font-weight: 500;
}

.form__button:disabled {
	background: #dddddd !important;
	cursor: not-allowed;
	opacity: 1 !important;
	border: none !important;
}

.button_modal:hover {
	background-color: #a1a2a7;
}
.form__button:hover {
	background: linear-gradient(to right, #09418e 0%, #0458cd 50%, #1f539d 100%);
}

.flex-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
}

.flex-col {
	display: flex;
	flex-direction: column;
}
.effect {
	height: 25px;
	width: 25px;
	transition: all 0.5s ease-out;
}
.effect:hover {
	transform: translateX(2px);
}
/* 
*{
	border: 1px solid red;
} */

.paginate-wrap {
	display: flex;
	align-items: center;
	list-style: none;
	margin: 1rem 0 0 0;
	padding: 1.5rem 1rem;
	justify-content: center;
	max-width: 100%;
	flex-wrap: wrap;
}

.paginate-a,
.paginate-next-a,
.paginate-prev-a,
.paginate-break-a {
	cursor: pointer;
	padding: 0.2rem 0.4rem;
}
/* &:focus {
		outline: 0;
	  }
  
	  &:hover {
		background: $purple-color;
		background: $purple-color-lighter;
	  } */

.paginate-active {
	border: 2px solid var(--primary-color);
	background: var(--primary-color);
	border-radius: 40px;
	color: white;
}

.paginate-disabled a {
	color: rgb(198, 197, 202);
	cursor: not-allowed;
}

.paginate-li {
	margin: 0 0.2rem;
	font-size: 16px;
	font-weight: 500;
}

.paginate-next-a,
.paginate-prev-a {
	color: var(--primary-color);
	margin: 0 0.3rem;
	font-size: 16px;
	font-weight: 500;
}

/* *{
	border: 1px solid red;
}  */
